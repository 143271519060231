"use client";

import * as React from "react";
import { BrainCog, ChartBarIcon, Moon, Sun, Menu, X, CreditCardIcon, ArrowUpRightIcon, PhoneCallIcon } from "lucide-react";
import { useTheme } from "next-themes";
import Link from "next/link";
import { Button } from "@/components/ui/button";
import { NavigationMenu, NavigationMenuItem, NavigationMenuLink, NavigationMenuList, navigationMenuTriggerStyle } from "@/components/ui/navigation-menu";
import { SignedOut, SignedIn, SignInButton, UserButton } from "@clerk/nextjs";
import { MoshizenLogo } from "./moshizen-logo";
export default function Navbar({
  loggedIn
}: {
  loggedIn: string;
}) {
  const {
    setTheme,
    theme
  } = useTheme();
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);
  let isLoggedIn = false;
  if (loggedIn && loggedIn !== "") {
    isLoggedIn = true;
    console.log("loggedIn", loggedIn);
  }
  const NavItems = () => <>
      {!isLoggedIn && <>
          <NavigationMenuItem>
            <Link href="/#pricing" legacyBehavior passHref>
              <NavigationMenuLink className={navigationMenuTriggerStyle()}>
                Pricing
              </NavigationMenuLink>
            </Link>
          </NavigationMenuItem>
          <NavigationMenuItem>
            <Link href="/#about" legacyBehavior passHref>
              <NavigationMenuLink className={navigationMenuTriggerStyle()}>
                About
              </NavigationMenuLink>
            </Link>
          </NavigationMenuItem>
        </>}
      {isLoggedIn && <NavigationMenuItem>
          <Link href="/dashboard/call" passHref>
            <Button variant="default">
              <PhoneCallIcon className="ml-2" /> Call Now
            </Button>
          </Link>
        </NavigationMenuItem>}

      <NavigationMenuItem data-sentry-element="NavigationMenuItem" data-sentry-source-file="navbar.tsx" data-sentry-element="NavigationMenuItem">
        <SignedOut data-sentry-element="SignedOut" data-sentry-source-file="navbar.tsx" data-sentry-element="SignedOut">
          <Link href="/onboarding" legacyBehavior passHref data-sentry-element="Link" data-sentry-source-file="navbar.tsx" data-sentry-element="Link">
            <Button variant="default" data-sentry-element="Button" data-sentry-source-file="navbar.tsx" data-sentry-element="Button">
              Get Started <ArrowUpRightIcon className="ml-2" data-sentry-element="ArrowUpRightIcon" data-sentry-source-file="navbar.tsx" data-sentry-element="ArrowUpRightIcon" />
            </Button>
          </Link>
        </SignedOut>
        {isLoggedIn && <Link href="/dashboard" legacyBehavior passHref>
            <Button variant="default">
              Dashboard <ArrowUpRightIcon className="ml-2" />
            </Button>
          </Link>}
      </NavigationMenuItem>
      <NavigationMenuItem data-sentry-element="NavigationMenuItem" data-sentry-source-file="navbar.tsx" data-sentry-element="NavigationMenuItem">
        <Button variant="outline" size="icon" onClick={() => setTheme(theme === "light" ? "dark" : "light")} data-sentry-element="Button" data-sentry-source-file="navbar.tsx" data-sentry-element="Button">
          <Sun className="h-[1.2rem] w-[1.2rem] rotate-0 scale-100 transition-all dark:-rotate-90 dark:scale-0" data-sentry-element="Sun" data-sentry-source-file="navbar.tsx" data-sentry-element="Sun" />
          <Moon className="absolute h-[1.2rem] w-[1.2rem] rotate-90 scale-0 transition-all dark:rotate-0 dark:scale-100" data-sentry-element="Moon" data-sentry-source-file="navbar.tsx" data-sentry-element="Moon" />
          <span className="sr-only">Toggle theme</span>{" "}
        </Button>
      </NavigationMenuItem>
      <NavigationMenuItem data-sentry-element="NavigationMenuItem" data-sentry-source-file="navbar.tsx" data-sentry-element="NavigationMenuItem">
        <SignedOut data-sentry-element="SignedOut" data-sentry-source-file="navbar.tsx" data-sentry-element="SignedOut">
          <SignInButton fallbackRedirectUrl="/dashboard" data-sentry-element="SignInButton" data-sentry-source-file="navbar.tsx" data-sentry-element="SignInButton" />
        </SignedOut>
        <SignedIn data-sentry-element="SignedIn" data-sentry-source-file="navbar.tsx" data-sentry-element="SignedIn">
          <div className="pl-2 pt-1">
            <UserButton data-sentry-element="UserButton" data-sentry-source-file="navbar.tsx" data-sentry-element="UserButton">
              <UserButton.MenuItems data-sentry-element="unknown" data-sentry-source-file="navbar.tsx" data-sentry-element="unknown">
                <UserButton.Link label="Dashboard" labelIcon={<ChartBarIcon />} href="/dashboard" data-sentry-element="unknown" data-sentry-source-file="navbar.tsx" data-sentry-element="unknown" />
                <UserButton.Link label="AI settings" labelIcon={<BrainCog />} href="/dashboard/settings/instructions" data-sentry-element="unknown" data-sentry-source-file="navbar.tsx" data-sentry-element="unknown" />
                <UserButton.Link label="Manage subscription" labelIcon={<CreditCardIcon />} href="/dashboard/settings/subscription" data-sentry-element="unknown" data-sentry-source-file="navbar.tsx" data-sentry-element="unknown" />
              </UserButton.MenuItems>
            </UserButton>
          </div>
        </SignedIn>
      </NavigationMenuItem>
    </>;
  return <header className="container mx-auto px-4 py-6" data-sentry-component="Navbar" data-sentry-source-file="navbar.tsx">
      <div className="flex justify-between items-center">
        <Link href="/" aria-label="TidyCalls Logo" data-sentry-element="Link" data-sentry-source-file="navbar.tsx" data-sentry-element="Link">
          <MoshizenLogo data-sentry-element="MoshizenLogo" data-sentry-source-file="navbar.tsx" data-sentry-element="MoshizenLogo" />
        </Link>
        <div className="hidden md:block">
          <NavigationMenu data-sentry-element="NavigationMenu" data-sentry-source-file="navbar.tsx" data-sentry-element="NavigationMenu">
            <NavigationMenuList data-sentry-element="NavigationMenuList" data-sentry-source-file="navbar.tsx" data-sentry-element="NavigationMenuList">
              <NavItems data-sentry-element="NavItems" data-sentry-source-file="navbar.tsx" data-sentry-element="NavItems" />
            </NavigationMenuList>
          </NavigationMenu>
        </div>
        <div className="md:hidden">
          <Button variant="ghost" size="icon" onClick={toggleMenu} data-sentry-element="Button" data-sentry-source-file="navbar.tsx" data-sentry-element="Button">
            {isMenuOpen ? <X /> : <Menu />}
          </Button>
        </div>
      </div>
      {isMenuOpen && <div className="md:hidden mt-4">
          <NavigationMenu className="flex flex-col space-y-2">
            <NavigationMenuList className="flex flex-col space-y-2">
              <NavItems />
            </NavigationMenuList>
          </NavigationMenu>
        </div>}
    </header>;
}